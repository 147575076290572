'use client'

import styled from '@emotion/styled'
import { StrictMode, type PropsWithChildren } from 'react'

import { TopNavBar } from '../../../ui-page-modules/top-nav-bar'
import { Footer } from '../../../ui-page-modules/footer/footer'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
})

/**
 * This component ensures the footer is below the fold
 * even on pages with little content. We still show a little
 * of the footer to indicate that it exists.
 */
const FoldContent = styled.div({ minHeight: 'calc(100vh - 24px)', display: 'flex', flexDirection: 'column' })
const Main = styled.main({ flex: 1 })

export default function Layout({ children }: PropsWithChildren) {
  return (
    <StrictMode>
      <Wrapper data-testid="global-page-wrapper">
        <FoldContent>
          <TopNavBar />
          <Main>{children}</Main>
        </FoldContent>
        <Footer />
      </Wrapper>
    </StrictMode>
  )
}
